@import 'mpc-variables-and-mixins.scss';

.pcf-flyout-content-scope {
    .flyout {
        border-bottom-right-radius: 2px;
        background-color: white;
        border: 1px solid rgb(235, 235, 235);
        display: flex;
        flex-direction: column;
        transition: 0.3s ease;
        z-index: 1;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        margin-top: -1px;
        width: 521px;
        min-height: 410px;
        opacity: 1;
        position: static;
        max-width: none;
        pointer-events: auto;
        justify-content: space-between;
        font-family: $esFontFamily;
        
        &.sticky-flyout {
            margin-top: 0;
            transition: none;

            &.fixed {
                position: fixed;
                top: 0;
                right: 0;
            }
        }

        &.pcf-show-flyout {
            opacity: 1;
            pointer-events: auto;
        }

        .flyout-content {
            min-height: 190px;
            margin-top: 4px;
            margin-bottom: 12px;

            .list-container {
                display: flex;
                flex-direction: column;

                .clear-list-btn {
                    right: 0;
                    margin-right: 18px;
                    font-size: 13px;
                    position: absolute;
                    text-decoration: underline;
                    cursor: pointer;
                    color: $esRed;
                }

                .items-container {
                    margin-top: 26px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    margin-right: 8px;
                    margin-left: 8px;

                    .list-item {
                        width: 90px;
                        min-height: 160px;
                        height: auto;
                        position: relative;
                        cursor: pointer;
                        text-decoration: none;
                        outline: none;

                        &::hover {
                            text-decoration: none;
                        }

                        &::focus {
                            outline: none;
                            text-decoration: none;
                        }

                        .mpc-recycle {
                            position: absolute;
                            top: -6px;
                            right: -4px;
                            background-color: white;
                            width: 25px;
                            height: 25px;
                            margin: auto;
                            fill: $esDarkGrey;
                            padding-left: 5px;
                            padding-bottom: 5px;
                            
                            svg {
                                width: 17px;
                                height: 25px;
                            }

                            &:hover {
                                fill: $esAllBlack;
                            }
                        }

                        .image-holder {
                            border: 1px solid $esMidGrey;
                            height: 90px;
                            width: 90px;
                            line-height: 88px;
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            .image-slot {
                                max-width: 88px;
                                max-height: 88px;
                                margin: auto;
                            }

                            .mpc-plus {
                                fill: $esDarkGrey;
                                position: absolute;
                                width: 26px;
                                height: 26px;
                                margin: auto;
                                top: 0;
                                bottom: 0;
                                right: 0;
                                left: 0;

                                &:hover {
                                    fill: $esAllBlack;
                                }
                            }
                        }

                        .description-slot {
                            margin-top: 5px;
                            font-size: 13px;
                            color: $esAllBlack;
                            line-height: 18.6px;
                        }

                        &:hover {
                            .description-slot {
                                color: $esAllBlack;
                            }
                        }
                    }

                    .empty-list-item {
                        height: 180px;

                        .image-holder {
                            line-height: normal;
                        }
                        // helps to prevent text jump when on empty slots on hover
                        .description-slot {
                            margin-right: 9px;
                        }

                        &:hover {
                            .mpc-plus {
                                fill: $esGrey;
                            }

                            .description-slot {
                                font-weight: normal;
                                color: $esAllBlack;
                            }
                        }

                        &.has-focus-keyboard {
                            outline-offset: 3px;
                            outline: 1px solid $esAllBlack;
                        }
                    }
                }
            }
        }
    }
}

@import 'mpc-variables-and-mixins.scss';

.pcf-scope .flyout-container {
    .empty-list-notification {
        padding: 0 10px;

        .description-text {
            margin: 0 24px;
            text-align: center;
            color: $esAllBlack;
        }

        .footer-container {
            display: flex;
            padding: 20px 0;
        }

        .navigate-to-products-button {
            height: 42px;
            line-height: 42px;
            background-color: $esRed;
            cursor: pointer;
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            flex-grow: 1;
            border-radius: 21px;

            .btn-text {
                margin-right: 20px;
                color: $esGrey3;
                white-space: nowrap;
                font-size: $esFontSize;
                font-weight: $esFontWeight;
                letter-spacing: $esLetterSpacing;
            }

            .navigate-products-button-icon {
                height: 15px;
                width: 10px;
                position: relative;
                margin-left: -10px;
                fill: $esGrey3;
                line-height: 15px;

                svg {
                    height: 15px;
                }
            }
        }

        .close-flyout-btn-footer {
            margin-right: 10px;
            flex-grow: 1;
        }
    }
}
@import 'mpc-variables-and-mixins.scss';

.mobileshop .pcf-scope {
    padding: inherit;
    -moz-transition: opacity 400ms ease-in;
    -webkit-transition: opacity 400ms ease-in;
    transition: opacity 400ms ease-in;
    z-index: 99999;
    font-family: $esFontFamily;

    .flyout-container {
        width: inherit;
        height: inherit;
        background: rgba(0, 0, 0, 0.8);
        bottom: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: fixed;
        right: 0;
        top: 0;
        max-height: inherit;
    }

    &.render-overlay {
        display: block;
        position: fixed;
        opacity: 1;

        .flyout-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            opacity: 1;
            pointer-events: auto;
        }
    }

    .flyout-container .flyout-icon-container {
        display: none !important;
    }

    .flyout {
        display: none !important;
    }
}
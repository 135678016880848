// DON'T put any styles here, ONLY mixins and variables. Every place where this file is included would duplicate any styles

$esRed: #E30613;
$esRedLight: #FF0818;
$esBlack: #1e1e1e;
$esBlackNew: #2e2e2e;
$esAllBlack: #000000;
$esGrey: #787878;
$esGrey2: #c5c5c5;
$esGrey3: #f4f3f1;
$esGrey4: #ebebeb;
$esWhite: #ffffff;
$esLightGrey: #F0F1F1;
$esMidGrey: #D3D4D4;
$esDarkGrey: #999999;
$esGreen: #0FAF0F;
$pageWidth: 961px;
$esFontSize: 14px;
$esFontFamily: Roboto, Arial, Helvetica, sans-serif;
$esFontWeight: 500;
$esLetterSpacing: 0.3px;
$esLineHeight: 18.6px;

@mixin navigation-tabs-mixin {
    .navigation-tabs {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;

        .navigation-tab {
            margin-top: 15px;
            margin-left: 10px;
            margin-right: 10px;
            height: 48px;
            line-height: 48px;
            max-width: calc(100% - 34px);
            border: none;
            border-radius: 25px;
            position: relative;
            cursor: pointer;
            color: $esAllBlack;
            background-color: $esLightGrey;
            transition: background-color 0.3s;
            padding: 0 40px 0 30px;
            text-decoration: none;
            outline: none;

            &:focus {
                outline: none;
                text-decoration: none;
            }

            &:hover {
                text-decoration: none;
                background-color: $esMidGrey;
            }

            .category-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: $esFontSize;
                font-weight: $esFontWeight;
                letter-spacing: $esLetterSpacing;       
            }

            .products-counter {
                position: absolute;
                height: 15px;
                width: 15px;
                top: 12px;
                right: 20px;
                font-size: 10px;
                line-height: 15px;
                text-align: center;
            }

            &.has-focus {
                outline-offset: 3px;
                outline: 1px solid $esAllBlack;
            }

            &.active-tab {
                background-color: $esAllBlack;
                cursor: default;
                pointer-events: none;
                color: white;

                &.has-focus {
                    outline-offset: 3px;
                    outline: 1px solid $esAllBlack;
                }
            }
        }
    }
}

@mixin disable-select-text {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@mixin hidden-row {
    visibility: hidden;
    height: 0 !important;
    margin: 0;
    padding: 0;
    border: none;
}

@mixin sticky-flyout-notification {
    transition: none;
    position: absolute;
    z-index: 1;

    &.fixed {
        position: fixed;
        top: 0;
        margin-top: 12px;
    }
}

@mixin pager-dot-wrapper {
    left: 12.5%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;

    .pager-dot {
        height: 10px;
        width: 10px;
        margin-left: 10px;
        background-color: $esMidGrey;
        display: inline-block;
        border-radius: 5px;

        &:first-child {
            margin-left: unset;
        }
    }

    .current-dot {
        background-color: $esRed;
    }
}

@mixin grid-column-defaults {
    &.slide-1 {
        transform: translateX(0);
    }

    &.slide-2 {
        transform: translateX(calc(-100% * 1));
    }

    &.slide-3 {
        transform: translateX(calc(-100% * 2));
    }
}

@mixin close-flyout-btn {
    .close-flyout-btn {
        left: 0px;
        background-image: none;
        background-position: unset;
        position: absolute;
        cursor: pointer;
        width: 50px;
        height: 50px;

        &::after,
        &::before {
            position: absolute;
            left: 23px;
            content: ' ';
            height: 16px;
            width: 3px;
            background-color: $esBlack;
            margin-top: auto;
            margin-bottom: auto;
            top: 0;
            bottom: 0;
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }
}

// DON'T put any styles here, ONLY mixins and variables. Every place where this file is included would duplicate any styles

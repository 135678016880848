@import 'mpc-variables-and-mixins.scss';

.pcf-scope .flyout-container {
    .new-product-notification {
        width: 380px;
        max-height: 250px;
        margin-left: -330px;

        .product-info-container {
            margin-top: 25px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            .image-slot {
                margin-left: 20px;
                margin-right: 20px;
                border: 1px solid $esMidGrey;
                width: 100px;
                line-height: 98px;
            }

            .description-container {
                display: inline-block;
                width: 233px;

                .title, .description {
                    display: inline;
                    color: $esAllBlack;
                }

                .title {
                    font-size: 13px;
                    font-weight: 500;
                }

                .description {
                    font-size: 13px;
                }

                .open-flyout-button {
                    margin-left: -4px;
                    margin-right: initial;
                }
            }
        }

        .footer-container {
            display: none;
        }
    }
}